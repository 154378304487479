var showMoreButtons = document.getElementsByClassName('show-more');
var lists = document.getElementsByClassName('doctor__link-list--hidden')

for(i = 0; i < lists.length; i++) {
    if(lists[i].childElementCount <= 4) {
        showMoreButtons[i].classList.add('hidden')
    } else {
        showMoreButtons[i].addEventListener('click', showListPoints)
    }
}

function showListPoints() {
    var linkList = this.parentNode;

    if (linkList.classList.contains('doctor__link-list--show')) {
        linkList.classList.add('doctor__link-list--hidden');
        linkList.classList.remove('doctor__link-list--show');
        this.innerHTML = 'Mehr Anzeigen';
    }
    else {
        linkList.classList.remove('doctor__link-list--hidden');
        linkList.classList.add('doctor__link-list--show');
        this.innerHTML = 'Weniger Anzeigen';
    }
}